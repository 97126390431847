import "./Footer.css";

import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <p>(c) 2022 | Built and designed with 🤍 by Ciara Cloud</p>
    </div>
  );
}
